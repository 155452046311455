html,
body,
#root,
.App {
  min-height: 100%;
  height: 100%;
}

html {
  min-height: 100%;
  background-color: #2a405b;
  background-image: url("images/bg.jpg");
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}

body {
  color: #7e97be;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
button {
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
  animation: bounce 1s infinite alternate;
  &.play {
    width: calc(max(10vw, 13em));
  }
  &.arrow {
    width: calc(max(8vw, 9em));
  }
}
.restart {
  color: #7e97be;
  padding:0.9em 1.2em;
  font-size: 1.2em;
  border: 2px solid;
  border-radius: 10em;
  margin-top: 1em;
}
.end {
  padding: 1em;
  font-size: 1.2em;
  p {
  }
}
a {
  color: #7e97be;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}
@keyframes bounce {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}
img {
  max-width: 100%;
}
.slide {
  margin: 8em;
  margin: 0 auto;
  padding-left: 5vw;
  padding-right: 5vw;
  margin-bottom: calc(max(5vh, 1.5vw));
  img {
    width: calc(min(50vh, 80vw));
  }
}
.loading {
  font-size: 3em;
}
.nowrap {
  white-space: nowrap;
}